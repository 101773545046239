import React, { useReducer, useEffect, useState } from 'react'
import reducer from '../store/reducer'
import MagicContext from './MagicContext'
import { auth } from '../services/firebase'
import { checkForUserResponse, getLastUserResponse, getBekindWithThisWorld } from '../auth/auth'
import { initialState, magicState } from '../utils/generalHelper'

function MagicProvider({ children }) {
  const magicLocalStorage = JSON.parse(localStorage.getItem('magicAuthentication'))
  const [state, dispatch] = useReducer(reducer, {
    ...magicState,
    ...magicLocalStorage,
  })
  const [updateState, setUpdateState] = useState(state)

  useEffect(() => {
    const unsubscribe = auth().onAuthStateChanged((user) => {
      if (user?.email?.split('@').includes('globant.com') || user?.email === 'alejandro.alvarez@crowe.com.ar') {
        checkForUserResponse().then((response) => {
          const updateData = {
            ...state,
            isSurveyComplete: response ? true : false,
            isAuthenticated: true,
          }

          localStorage.setItem('magicAuthentication', JSON.stringify(updateData))
          setUpdateState(updateData)
        })

        getLastUserResponse().then((response) => {
          const user = auth().currentUser
          if (response) {
            localStorage.setItem('userResponse', JSON.stringify(response))
            setUpdateState({ ...state, isAuthenticated: true })
          } else if (user) {
            const { displayName, email, photoURL } = user
            const userResponse = Object.assign({ displayName, email, photoURL }, initialState)
            localStorage.setItem('userResponse', JSON.stringify(userResponse))
            setUpdateState({ ...state, isAuthenticated: true })
          }
          getBekindWithThisWorld().then((res) => {
            localStorage.setItem('beKindWithThisWorld', JSON.stringify(res))
          })
        })
      } else {
        const updateData = {
          ...state,
          isAuthenticated: false,
        }
        setUpdateState(updateData)
        localStorage.setItem('magicAuthentication', JSON.stringify(updateData))
      }
    })

    return () => {
      unsubscribe()
    }
  }, [state])

  const value = {
    state: updateState,
    dispatch,
  }

  return <MagicContext.Provider value={value}>{children}</MagicContext.Provider>
}

export default MagicProvider
