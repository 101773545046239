export const holidaysPerCountry = {
  argentina: 14,
  brazil: 7,
  canada: 13,
  costarica: 11,
  ecuador: 13,
  chile: 9,
  colombia: 15,
  france: 11,
  india: 9,
  luxemburg: 11,
  mexico: 8,
  peru: 9,
  romania: 15,
  spain: 7,
  uk: 9,
  unitedArabEmirates: 0,
  uruguay: 11,
  usa: 8,
  usaCalifornia: 8,
  usaNorthCarolina: 8,
  usaTexas: 8,
  usaIllinois: 8,
  usaFlorida: 8,
  usaWashington: 8,
  usaNewYork: 8,
  belarus: 7,
  germany: 18,
}
