const conversionFactors = {
  Argentina: {
    electricPower: '0.4120',
    gasoline: '2.6924',
    diesel: '2.6924',
    naturalGas: '0.0019',
  },
  Belarus: {
    electricPower: '0.6756',
    gasoline: '2.6924',
    diesel: '2.6924',
    naturalGas: '0.0019',
  },
  Brazil: {
    electricPower: '0.0927',
    gasoline: '2.6924',
    diesel: '2.6924',
    naturalGas: '0.0019',
  },
  Canada: {
    electricPower: '0.1300',
    gasoline: '2.6924',
    diesel: '2.6924',
    naturalGas: '0.0019',
  },
  Chile: {
    electricPower: '0.4519',
    gasoline: '2.6924',
    diesel: '2.6924',
    naturalGas: '0.0019',
  },
  Colombia: {
    electricPower: '0.3670',
    gasoline: '2.6924',
    diesel: '2.6924',
    naturalGas: '0.0019',
  },
  Costa_Rica: {
    electricPower: '0.0824',
    gasoline: '2.6924',
    diesel: '2.6924',
    naturalGas: '0.0019',
  },
  Ecuador: {
    electricPower: '0.23',
    gasoline: '2.6924',
    diesel: '2.6924',
    naturalGas: '0.0019',
  },
  France: {
    electricPower: '0.0470',
    gasoline: '2.6924',
    diesel: '2.6924',
    naturalGas: '0.0019',
  },
  Germany: {
    electricPower: '0.3786',
    gasoline: '2.6924',
    diesel: '2.6924',
    naturalGas: '0.0019',
  },
  India: {
    electricPower: '0.9470',
    gasoline: '2.6924',
    diesel: '2.6924',
    naturalGas: '0.0019',
  },
  Mexico: {
    electricPower: '0.5270',
    gasoline: '2.6924',
    diesel: '2.6924',
    naturalGas: '0.0019',
  },
  Peru: {
    electricPower: '0.5470',
    gasoline: '2.6924',
    diesel: '2.6924',
    naturalGas: '0.0019',
  },
  Romania: {
    electricPower: '0.4010',
    gasoline: '2.6924',
    diesel: '2.6924',
    naturalGas: '0.0019',
  },
  Spain: {
    electricPower: '0.2880',
    gasoline: '2.6924',
    diesel: '2.6924',
    naturalGas: '0.0019',
  },
  UK: {
    electricPower: '0.2773 ',
    gasoline: '2.6924',
    diesel: '2.6924',
    naturalGas: '0.0019',
  },
  Uruguay: {
    electricPower: '0.3359',
    gasoline: '2.6924',
    diesel: '2.6924',
    naturalGas: '0.0019',
  },
  USA: {
    electricPower: '0.7072',
    gasoline: '2.6924',
    diesel: '2.6924',
    naturalGas: '0.0019',
  },
}

export function getConversionFactorsByCountry(country, conversionFactor) {
  const type = conversionFactor || 'diesel'
  return conversionFactors[country.replace(' ', '_')][type]
}
