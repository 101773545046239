import { auth, googleProvider, fStore } from '../services/firebase'
import { initialState } from '../utils/generalHelper'
import { scream } from '../utils/prettyMessages'

export function signInWithGoogle() {
  const globantProvider = googleProvider.setCustomParameters({})

  return auth().signInWithPopup(globantProvider)
}

export async function checkForUserResponse() {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    const user = await auth().currentUser
    if (!user) {
      resolve(false)
    } else {
      const { uid } = user
      const userRef = fStore().collection('carbonFootprintResults').doc(uid)
      try {
        const doc = await userRef.get()
        resolve(doc.exists)
      } catch (error) {
        console.log('Error al obtener documento:', error)
        resolve(false)
      }
    }
  })
}

export function logOut() {
  return auth().signOut()
}

export function sessionPersistence() {
  return auth().setPersistence(auth.Auth.Persistence.LOCAL)
}

export function createProject(data) {
  let user = auth().currentUser
  const { uid, displayName, email, photoURL } = user

  const userRef = fStore().collection('carbonFootprintResults').doc(uid)
  sessionPersistence()
  const {
    countrySelected,
    vehiclesSelectedData,
    vehiclesSelected,
    totalGHGEmissions,
    totalWFHEmissions,
    homeConsumption: { homeOffice, devicesEnergy, houseCooling, houseHeating, electricityUse },
    equivalences,
  } = data

  const result = {
    uid: uid,
    TotalEmissions: totalGHGEmissions + totalWFHEmissions,
    GHGEmissions: totalGHGEmissions,
    WFHEmission: totalWFHEmissions,

    displayName,
    email,
    photoURL,
    location: countrySelected,
    data,
    vehiclesSelected: vehiclesSelected,
    vehiclesSelectedData,

    homeConsumption: {
      homeOffice,
      devicesEnergy,
      houseCooling,
      houseHeating,
      electricityUse,
    },
    equivalences: equivalences,
  }
  localStorage.setItem('userResponse', JSON.stringify(result))
  // return fStore()
  // .collection('carbonFootprintResults')
  // .where('uid', '==', uid)
  // .get()
  // .then((res) => {
  //   let data = null
  //   res.forEach((item) => {
  //     if (item.data()) {
  //       data = item.data()
  //     }
  //   })

  //   if (data) {
  //     return fStore().collection('carbonFootprintResults').doc(uid).update(result)
  //   }
  //   return fStore().collection('carbonFootprintResults').doc(uid).set(result)
  // })
  return userRef.set(result, { merge: true })
}

export async function validateAccountCheck() {
  let user = auth().currentUser
  const { uid, displayName, email, photoURL } = user

  return fStore()
    .collection('users')
    .doc(uid)
    .set({ displayName: displayName, email: email, photoURL: photoURL }, { merge: true })
    .then(() => {
      return true
    })
    .catch((err) => {
      console.log(`You are NOT in the white-listed domain ${scream} ${scream} ${scream}`, err)
      return false
    })
}

export async function getLastUserResponse() {
  let user = auth().currentUser
  const { uid } = user
  const userRef = fStore().collection('carbonFootprintResults').doc(uid)
  const doc = await userRef.get().then((doc) => doc.data())
  return doc
}

export async function getBekindWithThisWorld() {
  let user = auth().currentUser
  const emptyBeKindToThisWorld = {
    countrySelected: {},
    vehiclesSelected: null,
    totalGHGEmissions: 0,
    totalWFHEmissions: 0,
    equivalences: {},
    vehiclesSelectedData: {},
    homeConsumption: {},
    requestStatus: {
      sending: false,
      success: false,
      error: false,
    },
  }
  if (!user) return emptyBeKindToThisWorld

  const { uid } = user
  const userRef = fStore().collection('carbonFootprintResults').doc(uid)
  const doc = await userRef.get().then((doc) => doc.data())
  /* eslint-disable-next-line no-extra-boolean-cast */
  if (!!doc) {
    const {
      location,
      vehiclesSelectedData,
      vehiclesSelected,
      GHGEmissions,
      WFHEmission,
      homeConsumption,
      equivalences,
    } = doc
    return {
      countrySelected: location,
      vehiclesSelected,
      totalGHGEmissions: GHGEmissions,
      totalWFHEmissions: WFHEmission,
      equivalences,
      vehiclesSelectedData,
      homeConsumption,
      requestStatus: {
        sending: false,
        success: false,
        error: false,
      },
    }
  }
  return initialState
}
