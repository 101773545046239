const averageFuelConsumption = {
  car: {
    gasoline: 8333.333333,
    diesel: 9090.909091,
    naturalGas: 10,
  },
  taxi: {
    diesel: 9090.909091,
  },
  bus: {
    diesel: 2000,
  },
  combi: {
    diesel: 9090.909091,
  },
  subway: {
    electricPower: 282.4858757,
  },
  motorcycle: {
    gasoline: 33333.33333,
  },
}

export function getVehiclePerformance(vehicle, fuelUsed) {
  return averageFuelConsumption[vehicle][fuelUsed]
}
