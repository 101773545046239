import { initial, toNumber, flatten, sum, get } from 'lodash'
import { getConversionFactorsByCountry } from '../utils/emissionFactorOfFuels'
import { getVehiclePerformance } from '../utils/vehiclePerformance'
import defaultTrips from '../utils/defaultTrips'

const blackList = ['car', 'motorcycle']

function updateNextVehicle(vehiclesSelectedData, vehicleIndex, tripIndex, vehicleData) {
  const { value, disableDays } = vehicleData

  const weeklyJourneysMade = {
    ...vehiclesSelectedData[vehicleIndex].trips[tripIndex].weeklyJourneysMade,
    disableDays: toNumber(value) + disableDays - 1,
  }

  const trip = {
    ...vehiclesSelectedData[vehicleIndex].trips[tripIndex],
    weeklyJourneysMade,
  }

  const trips = [trip]

  const vehicle = {
    ...vehiclesSelectedData[vehicleIndex],
    trips,
  }

  const updateVehiclesSelectedData = [
    ...vehiclesSelectedData.slice(0, vehicleIndex),
    vehicle,
    ...vehiclesSelectedData.slice(vehicleIndex + 1),
  ]

  return updateVehiclesSelectedData
}

export const setVehiclesSelectedData = (vehicles) =>
  vehicles.map((vehicleType) => {
    return {
      vehicle: vehicleType,
      trips: [defaultTrips(vehicleType)],
      secondTrip: false,
    }
  })

export const addDefaultSecondTrip = (vehicleType, vehiclesSelectedData) => {
  const vehicleSelected = vehiclesSelectedData.map((vehicleSelected) => {
    const { vehicle, trips, secondTrip } = vehicleSelected

    if (vehicle === vehicleType) {
      return {
        vehicle,
        trips: [...trips, defaultTrips(vehicleType)],
        secondTrip: true,
      }
    }

    return {
      vehicle,
      trips,
      secondTrip,
    }
  })

  return vehicleSelected
}

export const removeSecondTrip = (vehicleType, vehiclesSelectedData) => {
  const vehicleSelected = vehiclesSelectedData.map((vehicleSelected) => {
    const { vehicle, trips, secondTrip } = vehicleSelected

    if (vehicle === vehicleType) {
      const removeLastTrip = initial(trips)

      return {
        vehicle,
        trips: removeLastTrip,
        secondTrip: false,
      }
    }

    return {
      vehicle,
      trips,
      secondTrip,
    }
  })

  return vehicleSelected
}

export const updateWeeklyJourneysMade = (payload, vehiclesSelectedData) => {
  const { disabledDays } = payload
  const weeklyJourneysMade = {
    ...vehiclesSelectedData[0].trips[0].weeklyJourneysMade,
    disableDays: disabledDays,
  }
  const trip = {
    ...vehiclesSelectedData[0].trips[0],
    weeklyJourneysMade,
  }

  const trips = [trip]

  const vehicle = {
    ...vehiclesSelectedData[0],
    trips,
  }

  const updateVehiclesSelectedData = [
    ...vehiclesSelectedData.slice(0, 0),
    vehicle,
    ...vehiclesSelectedData.slice(0 + 1),
  ]

  return updateVehiclesSelectedData
}

export const tripOptionsChange = ({ tripIndex, vehicleIndex, optionData: { type, ...rest } }, vehiclesSelectedData) => {
  let vehicleData = vehiclesSelectedData[vehicleIndex]
  let selectedVehiclesData = [...vehiclesSelectedData]

  const trips = [...vehicleData.trips]

  trips.splice(tripIndex, 1, {
    ...defaultTrips(vehicleData.vehicle),
    ...vehicleData.trips[tripIndex],
    [type]: {
      ...vehicleData.trips[tripIndex][type],
      ...rest,
    },
  })

  selectedVehiclesData.splice(vehicleIndex, 1, {
    ...vehicleData,
    trips,
  })

  if (type === 'weeklyJourneysMade' && selectedVehiclesData.length - 1 !== vehicleIndex) {
    const disableDays = trips[tripIndex].weeklyJourneysMade.disableDays

    selectedVehiclesData = updateNextVehicle(selectedVehiclesData, vehicleIndex + 1, tripIndex, {
      ...rest,
      disableDays,
    })
  }

  return selectedVehiclesData
}

export const estimateCarbonFootprint = (data, countrySelected) => {
  const vehiclesSelectedData = data.map((data) => {
    const { trips, ...rest } = data

    if (blackList.includes(rest.vehicle)) {
      const updateTrips = trips.map((trip) => {
        const getEngineTypeValue = get(trip, 'engineType.value')

        if (getEngineTypeValue === 'electricPower') {
          const { engineType, currentModeOfTransportationToWork, weeklyJourneysMade } = trip

          return {
            engineType,
            currentModeOfTransportationToWork,
            weeklyJourneysMade,
            passengersTravelInThisVehicle: {},
            travelDistance: {},
          }
        }

        return trip
      })

      return {
        ...rest,
        trips: updateTrips,
      }
    }

    return {
      ...rest,
      trips,
    }
  })

  const getTotalGHGEmissions = getDailyTour(vehiclesSelectedData, countrySelected)

  return {
    vehiclesSelectedData,
    totalGHGEmissions: getTotalGHGEmissions,
  }
}

export const getDailyTour = (data, countrySelected) => {
  const annualBusinessDays = 261
  const { country } = countrySelected
  const trips = data.map((vehicleData) => ({
    trips: vehicleData.trips,
    vehicle: vehicleData.vehicle,
  }))

  const distancesByTrip = trips.map((data) => {
    const { trips, vehicle } = data
    const distances = []
    let conversionFactorType = ''

    trips.forEach((data) => {
      if (data.ecoFriendly) return

      const getEngineTypeValue = get(data, 'engineType.value')
      if (getEngineTypeValue === 'electricPower' && blackList.includes(vehicle)) return

      conversionFactorType = data.engineType.value
      const { travelDistance, weeklyJourneysMade, passengersTravelInThisVehicle, engineType } = data
      const kmTraveled = toNumber(travelDistance.value) * toNumber(weeklyJourneysMade.value)
      const dailyTour = kmTraveled / 5
      const vehiclePerformance = getVehiclePerformance(vehicle, engineType.value)
      const totalOfCompanions =
        vehicle === 'car'
          ? toNumber(passengersTravelInThisVehicle.value) + 1
          : toNumber(passengersTravelInThisVehicle.value)
      const dailyConsumption = dailyTour / vehiclePerformance / totalOfCompanions

      distances.push(dailyConsumption)
    })

    const totalDailyConsumption = sum(distances)
    const annualConsumption = totalDailyConsumption * annualBusinessDays
    const annualFootprint = annualConsumption * getConversionFactorsByCountry(country, conversionFactorType)

    return annualFootprint
  })

  const flattenValues = flatten(distancesByTrip)
  const totalValue = sum(flattenValues)
  const transformToKg = totalValue * 1000

  return toNumber(transformToKg)
}
