const Strings = {
  LOGIN_START_SESSION_BUTTON: 'Login with Google',
  LOGIN_DESCRIPTION_TEXT:
    'All our actions make an impact on the environment. At Globant we are committed to being kind to the Planet. Measure your Work from home Carbon Footprint by completing this survey and join us on our race to zero emissions.',
  SELECTION_TITLE_CITY: 'What site were you assigned to since the year started?',
  SELECTION_PLACEHOLDER_COUNTRY: 'Country',
  SELECTION_PLACEHOLDER_CITY: 'City',
  SELECTION_TITLE: 'What means of transport do you usually use in your commute?',
  SELECTION_DESCRIPTION_TEXT:
    'Consider means of transport that you use at least once a week. More than one option is possible.',
  HOME_CONSUMPTION: {
    SECTIONS: {
      HOME_OFFICE: {
        TITLE: 'Home office',
        QUESTIONS: {
          WORKING_FULL_TIME: {
            TITLE: 'Are you still working full time from home?',
            DESCRIPTION: 'Please bear in mind your most common working scenario since the year started.',
            OPTIONS: {
              YES: {
                label: 'Yes',
                value: 'yes',
              },
              NO: {
                label: 'No',
                value: 'no',
              },
            },
          },
          AA: {
            TITLE: 'How many days a week are you still working from home?',
            DESCRIPTION: 'Please take into consideration the most common working scenario since the year started.',
            OPTIONS: {
              NO_DAYS: ', no days',
              COUNTER_OPTIONS: 6,
            },
          },
          AB: {
            TITLE: 'How many hours a day do you work at home?',
            DESCRIPTION: 'Please take into consideration the most common working scenario since the year started.',
            OPTIONS: {
              COUNTER_OPTIONS: 9,
            },
          },
          B: {
            TITLE: 'How many days of time off (vacation and/or any leave) have you enjoyed since the year started?',
            OPTIONS: {
              DAYS: 'days',
            },
          },
        },
      },
      DEVICES_ENERGY: {
        TITLE: 'Devices & Energy',
        SUBTITLE: 'It is important get to know more about your electricity consumption during your working hours.',
        QUESTIONS: {
          NUMBER_OF_SCREENS: {
            TITLE: 'Besides your laptop or PC, how many extra monitors do you use for your tasks?',
            OPTIONS: {
              COUNTER_OPTIONS: 5,
              NO_DAYS: 'None',
            },
          },
          HAVE_RENEWABLE_ENERGY: {
            TITLE: 'Do you supply your home with renewable energy?',
            DESCRIPTION:
              'We would like to know if your home is supplied with technology sourced by the sun, the wind, biomass, among other renewable sources. This could be a domestic installation or a renewable energy supplier service.',
            OPTIONS: {
              YES: {
                label: 'Yes',
                value: 'yes',
              },
              NO: {
                label: 'No',
                value: 'no',
              },
            },
          },
          TYPE_OF_RENEWABLE_ENERGY: {
            TITLE: 'Which renewable energy sources do you use?',
            OPTIONS: [
              'Photovoltaic Solar Panel',
              'Solar Water Heater',
              'Biogas generator',
              'Wind-power generator',
              'Renewable energy supplier.',
              'Other',
            ],
          },
          ENERGY_PERCENTAGE: {
            TITLE: 'Which percentage of your home energy consumption is supplied by renewable energy?',
          },
        },
      },
      HOUSE_COOLING: {
        TITLE: 'House cooling',
        DESCRIPTION:
          'It is important to get to know more about your cooling energy consumption during your working hours.',
        QUESTIONS: {
          GLOBAL: {
            HAVE_AIR_CONDITIONING: {
              TITLE: 'Do you have air conditioning equipment in your home workspace?',
              OPTIONS: {
                YES: {
                  label: 'Yes',
                  value: 'yes',
                },
                NO: {
                  label: 'No',
                  value: 'no',
                },
              },
            },
            MANY_FRIGORIES: {
              TITLE: 'Which is the size of your air conditioning?',
              OPTIONS: {
                SMALL: {
                  label: 'Small (9-15 m2)',
                  value: 'small',
                },
                MEDIUM: {
                  label: 'Medium (16-25 m2)',
                  value: 'medium',
                },
                LARGE: {
                  label: 'Large (more than 25 m2)',
                  value: 'large',
                },
              },
            },
            MANY_MONTHS_USE: {
              TITLE:
                'For how many months have you used an air conditioner for cooling your home workspace since the year started?',
              OPTIONS: {
                COUNTER: 12,
                INIT: 1,
              },
            },
            MANY_HOURS_USE: {
              TITLE: 'For how many hours you use that air conditioner in a normal work day?',
              OPTIONS: {
                COUNTER: 9,
                INIT: 1,
              },
            },
            USE_ANOTHER_DEVICE: {
              TITLE: 'Do you use any other electric device for cooling your home workspace?',
              OBSERVATION: '(for example a fan)',
              OPTIONS: {
                YES: {
                  label: 'Yes',
                  value: 'yes',
                },
                NO: {
                  label: 'No',
                  value: 'no',
                },
              },
            },
            MANY_MONTHS_USE_ANOTHER: {
              TITLE: 'For how many months have you used that other electric device since the year started?',
              OPTIONS: {
                COUNTER: 12,
                INIT: 1,
              },
            },
            MANY_HOURS_USE_ANOTHER: {
              TITLE: 'For how many hours do you use that other electric device in a normal work day?',
              OPTIONS: {
                COUNTER: 9,
                INIT: 1,
              },
            },
          },
          INDIA: {
            TYPE_AIR_CONDITIONING: {
              TITLE: 'What type of AC do you have?',
              OPTIONS: {
                WINDOWS_AC: {
                  label: 'Windows AC',
                  value: 'windowAC',
                },
                SPLIT_AC: {
                  label: 'Split AC',
                  value: 'splitAC',
                },
                CASSETTE_AC: {
                  label: 'Cassette AC',
                  value: 'cassetteAC',
                },
                OTHER: {
                  label: 'Other',
                  value: 'other',
                },
              },
            },
            CAPACITY_AIR_CONDITIONING: {
              TITLE: 'Which is the capacity of your AC unit?',
              OPTIONS: {
                TON_1: {
                  label: '1 ton',
                  value: '1ton',
                },
                TON_2: {
                  label: '2 ton',
                  value: '2ton',
                },
                TON_3: {
                  label: '3 ton',
                  value: '3ton',
                },
                OTHER: {
                  label: 'Other',
                  value: 'other',
                },
              },
            },
          },
        },
      },
      HOUSE_HEATING: {
        TITLE: 'House heating',
        DESCRIPTION:
          'It Is important to get to know more about your heating energy consumption during your working hours.',
        A: {
          TITLE: 'Do you use a device for heating your home workspace? Which one?',
          DESCRIPTION: '(if you have more than one, please select the one you have used more in your home workspace)',
          OPTIONS: [
            'Electric heating',
            'Gas heater',
            'Electric boiler',
            'Gas boiler',
            'Radiating floor',
            'Gas cylinder heater',
            'Air conditioning',
            'Wood stove',
            'I don’t use',
          ],
        },
        AB: {
          TITLE: 'For how many months have you used this heating device since the year started?',
          OPTIONS: {
            COUNTER_OPTIONS: 12,
          },
        },
        AC: {
          TITLE: 'For how many hours do you use this heating device in a normal work day?',
          OPTIONS: {
            COUNTER_OPTIONS: 9,
          },
        },
      },
      ELECTRICITY_USE: {
        TITLE: 'Home consumption',
        QUESTIONS: {
          CONSUMPTION_ASSOCIATED: {
            TITLE:
              'Indicate which percentage of your reported information is allocated to your working from home consumptions.',
            DESCRIPTION:
              'Please answer 0% if your reported consumptions were to occur working from the office anyway (e.g. workspace shared with other members of your home). 100% if everything would be turned off if you were working from the office.',
            OPTIONS: {
              MIN: '0%',
              MAX: '100%',
            },
          },
          TyC: {
            DESCRIPTION:
              'Globant will treat your answer in a strictly confidential manner. By completing this survey, you authorize Globant and any contracted third party, to process the information provided to perform the analysis, according to the previously explained, and in accordance with, and in the terms of, the applicable regulations for the country you are in. The information will be stored in Globant´s systems for a period of one (1) year, but you can request its return and/or deletion prior to the expiration of term, by contacting privacy@globant.com',
            OPTIONS: {
              AGREE: {
                label: 'I agree',
                value: 'yes',
              },
            },
          },
        },
      },
    },
  },
  TRANSPORT_MEAN_AUTO: 'Car',
  TRANSPORT_MEAN_TAXI: 'Taxi',
  TRANSPORT_MEAN_BUS_VAN: 'Bus, Combi van',
  TRANSPORT_MEAN_TRAIN_SUB: 'Train, Subway',
  TRANSPORT_MEAN_MOTORBIKE: 'Motorbike',
  TRANSPORT_MEAN_ELECTRIC_SCOOTER: 'Non powered scooter',
  TRANSPORT_MEAN_BIKE: 'Bike',
  TRANSPORT_MEAN_WALK: 'On foot',
  TRANSPORT_MEAN_HOME: 'Home Office',
  CONTINUE_BUTTON: 'Continue',
  CARBON_FOOTPRINT_BUTTON: 'Estimate carbon footprint',
  VEHICLE_TYPE_1: 'Gasoline/Petrol',
  VEHICLE_TYPE_2: 'Diesel',
  VEHICLE_TYPE_3: 'Electric Power',
  VEHICLE_TYPE_4: 'Natural Gas',
  TRIPS_HEADER_1: 'First trip',
  TRIPS_HEADER_2: 'Additional Trip',
  TRIPS_HEADER_3: 'Third travel',
  TRIPS_QUESTIONS_1: 'How do you power your vehicle?',
  TRIPS_QUESTIONS_2: 'What is the approximate round trip distance you travel per day?',
  TRIPS_QUESTIONS_3: 'How many of these round trip do you make weekly?',
  TRIPS_QUESTIONS_4: 'How many passengers usually travel with you?',
  TRIPS_QUESTIONS_5: 'Please, select the main reasons why you choose this means of transport for your commute.',
  TRIPS_QUESTIONS_SUB_2:
    'Keep in mind a round trip includes the distance from your home to the office and back, not a one way distance.',
  TRIPS_ADD_TRIP_DESC: `If you also use this type of vehicle for additional trips related but not limited to client meetings whilst they are hosting or visits to other sites be sure to Add Trip in order to estimate its carbon footprint.`,
  TRIPS_TRAVEL_ALONE: 'I travel alone',
  TRIPS_ADD_TRIP: '+ Additional Trip',
  TRIPS_REMOVE_TRIP: '- Remove Trip',
  TRIPS_NO_EMISSIONS: 'This means of transport does not generate carbon footprint. Well done!',
  BACK_STEP: 'Previous',
  LOADING_TEXT: 'calculating results',
  RESULT: {
    EMISSIONS: {
      TITLE: 'Well done! You have completed the survey',
      SUBTITLE: 'This is your current carbon footprint',
      D_TITLE: 'Well done, ',
      D_SUBTITLE: 'This is your carbon footprint dashboard',
      EMISSION_BAR_CHART: 'Emissions comparison',
      EMISSION_DONUT_CHART: 'Your latest emissions',
      EMISSIONLABEL:
        'These numbers represent the average amount of CO₂e emissions you produce per year while working from home and/or commuting.',
      EMISSIONLABEL2: 'Your previous result = ',
      EMISSIONLABEL3: 'From ',
      GREAT_JOB_TITLE: 'Great job on your first footprint calculation!',
      GREAT_JOB_DESCRIPTION:
        'We will ask you to update your activity in the following months so you can see the evolution of your footprint. Keep up with the good work!',
      DECREASED_EMISSIONS_TITLE: 'Amazing! Your footprint decreased',
      DECREASED_EMISSIONS_BASE: 'Based on your last answers, you are now generating',
      DECREASED_EMISSIONS_DESCRIPTION: 'kg CO₂e less emissions. Keep up with the good work!',
      INCREASED_EMISSIONS_TITLE: 'Oh no! Your footprint increased',
      INCREASED_EMISSIONS_DESCRIPTION:
        ' kg CO₂e more emissions. Let’s keep working on our habits and reducing our carbon footprint!',
      DESCRIPTION: 'The amount of emissions you generate is equivalent to',
      EMISSIONLABEL_WELCOME_BACK:
        'Average amount of CO₂e emissions you produce per year, while working from home and/or commuting.',
      EQUIVALENCES: {
        EMAILS_SENT: 'emails sent',
        ZOOM_HOURS: 'hours on Zoom',
        NETFLIX_HOURS: 'hours on Netflix',
      },
    },
    GENERAL: {
      READY: 'Are you ready to reduce your emissions? ',
      THANK_YOU: 'Thank you for being part of our race to zero emissions!',
      CONTACT_SOON: 'We will meet again in a few months to keep your carbon footprint up to date.',
      KNOW_MORE: 'Get to know more about Be kind to the Planet initiatives and switch on your positive impact!',
      INVITE: 'If you want to calculate your personal carbon footprint, we invite you to answer ',
      CONTACT: 'For any questions or suggestions please reach us out at: ',
      LINKS: {
        STARTED: {
          TEXT: 'Go to the site',
          URL: 'https://sites.google.com/globant.com/bekind/home',
        },
        SURVEY: {
          TEXT: 'this survey',
          URL: 'https://www.footprintcalculator.org/',
        },
      },
    },
    ACTIONS: {
      QUESTION: 'How to reduce your carbon emission?',
      SUB_QUESTION: 'With some small changes in your working habits you can make a difference!',
      ITEMS: {
        RENEWABLE_ENERGY: {
          TITLE: 'Supply your home with renewable energy sources',
          SUBTITLE: 'Get to know if your country has suppliers who can help you with this.',
        },
        CARPOOLING: {
          TITLE: 'Do carpooling!',
          SUBTITLE: 'Meet Globers who live near your home with NearBy and share your drive to the office.',
        },
        GREEN_COMMUTING: {
          TITLE: 'Green your commuting',
          SUBTITLE:
            'Choose electric-powered vehicles and public transport or just enjoy a bike or on-foot ride to the office.',
        },
      },
    },
    DIGITAL_PRODUCTS: {
      TITLE: 'Did you know that digital products also have a footprint?',
      CARD: {
        EMISSIONS_TITLE: 'CO2e emissions from the Internet are HUGE',
        EMISSIONS_DESCRIPTION:
          "If the Internet were a country, it would be the equivalent of Germany, the 6th most polluted country in the world. It's basically because everything on the internet uses energy (data centers, transmission networks, and devices).",
        EMAILS_TITLE: 'If all the people in the world delete 10 emails we would save 43,000 tons of CO2e emissions',
        EMAILS_DESCRIPTION:
          'All the emails you send and receive are stored in the cloud, these large machines that store data need tons of energy and fuel to function, producing a high carbon footprint.',
        NETFLIX_TITLE: 'Chill on Netflix!',
        NETFLIX_DESCRIPTION:
          'All the emails you send and receive are stored in the cloud, these large machines that store data need tons of energy and fuel to function, producing a high carbon footprint.',
      },
    },
  },
  WELCOME_BANNER: {
    TITLE: 'Have you changed your working habits in the last months?',
    SUBTITLE: 'Update your previous answers and see if your carbon footprint has evolved!',
    BUTTON_TEXT: 'Update my answers',
  },
  PAGE404_TITLE: 'Error 404 Not Found',
  PAGE404_DESCRIPTION: 'We can’t find the page that you’re looking for',
  PAGE404_BACK_BUTTON: 'Back to home',
  ADDRESS_NOT_FOUND_TITLE: 'Sorry, the address was not found',
  ADDRESS_NOT_FOUND_DESCRIPTION: 'Try again with your Globant box',
  ADDRESS_NOT_FOUND_BACK_BUTTON: 'Back to home',
  SURVEY_COMPLETE_TITLE: 'The survey is already completed',
  SURVEY_COMPLETE_DESCRIPTION: 'Thank you very much for participating!',
  MENU_YOUR_OFFICE: 'Your office',
  MENU_HOME_CONSUMPTION: 'Home consumption',
  MENU_VEHICLES: 'Vehicles',
  MENU_YOUR_TRIPS: 'Your Trips',
  MENU_RESULTS: 'Results',
  SELECTION_SECTION_CAR: 'Car',
  SELECTION_SECTION_TAXI: 'Taxi',
  SELECTION_SECTION_BUS: 'Bus',
  SELECTION_SECTION_COMBI: 'Combi van',
  SELECTION_SECTION_TRAIN: 'Train, Subway',
  SELECTION_SECTION_MOTORCYCLE: 'Motorbike',
  SELECTION_SECTION_SCOOTER: 'Non powered scooter',
  SELECTION_SECTION_BICYCLE: 'Bike',
  SELECTION_SECTION_WALKING: 'On foot',
  SELECTION_SECTION_WFO: 'Home Office',
  VEHICLE_CHOOSING_1: 'Comfort',
  VEHICLE_CHOOSING_2: 'Rapidity',
  VEHICLE_CHOOSING_3: 'Cost',
  VEHICLE_CHOOSING_4: 'Convenience',
  VEHICLE_CHOOSING_5: 'Personal safety',
  VEHICLE_CHOOSING_6: 'Lower risk of accident',
  VEHICLE_CHOOSING_7: 'There’s no access to the public transport',
  VEHICLE_CHOOSING_8: 'Health',
  VEHICLE_CHOOSING_9: 'Physical disability conditions',
  VEHICLE_CHOOSING_10: 'Commitments (drop off or pick someone up)',
  VEHICLE_CHOOSING_11: 'Environmental reasons',
  VEHICLE_CHOOSING_12: 'Other',
  TERMS_AND_CONDITIONS:
    'Globant will treat your answer in a strictly confidential manner. By completing this survey, you authorize Globant and any contracted third party, to process the information provided to perform the analysis, according to the previously explained, and in accordance with, and in the terms of, the applicable regulations for the country you are in. The information will be stored in Globant´s systems for a period of one (1) year, but you can request its return and/or deletion prior to the expiration of term, by contacting',
  HOME: {
    EQUIVALENCES: {
      TITLE: 'What does it mean?',
      SUBTITLE: 'This means that the amount of emissions you are generating is equivalent to',
      ITEMS: {
        PIANO: {
          calculateEquivalent: function (emissions) {
            return `The weight of ${(emissions / 317).toFixed(2)} grand pianos!`
          },
          SUBTITLE: 'Just imagine that multiplied by each person you know.',
        },
        CAR: {
          calculateEquivalent: function (emissions) {
            return `${(emissions / 0.404).toFixed(2)} miles driven`
          },
          SUBTITLE: 'by an average gasoline-powered passenger vehicle',
        },
        CHARGER: {
          calculateEquivalent: function (emissions) {
            return `${(emissions / 60).toFixed(2)} smartphones charged`
          },
          SUBTITLE: 'from 0 to 100% (that’s a lot of phones!)',
        },
        FOREST: {
          calculateEquivalent: function (emissions) {
            return `It would take ${(emissions / 420).toFixed(2)} acres`
          },
          SUBTITLE: 'of US forest in a year to compensate it.',
        },
      },
    },
  },
}
export default Strings
